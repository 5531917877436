<script>
import appConfig from "@/app.config";
import CalendarApiService from "@/services/calendarService";
import EventDetailsModal from "@/components/event-details-modal";
import BulkChangeModal from "@/router/views/workOrders/modals/bulkChangeModal";
import WorkOrderSearchTypeahead from "@/components/widgets/workOrderSearchTypeahead";
import WorkOrderGroupTypeahead from "@/components/widgets/workOrderGroupTypeahead";
import DeletionModal from '../../../components/widgets/deletion-modal.vue'
import ListFilters from "./components/list-filters";
import List from "./components/list";
import ListFieldFilters from "./components/list-field-filters";

/**
 * Dashboard Component
 */

export default {
  page: {
    title: "workOrders",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    EventDetailsModal,
    WorkOrderSearchTypeahead,
    // eslint-disable-next-line vue/no-unused-components
    WorkOrderGroupTypeahead,
    BulkChangeModal,
    // eslint-disable-next-line vue/no-unused-components
    ListFilters,
    List,
    ListFieldFilters,
    DeletionModal,
  },
  data() {
    return {
      selectedWorkOrder: null,
      selectedWorkOrderProgress: {
        progress: {
          name: null,
        },
        details: null,
      },
      showConfirmDeleteModal: false,
      showConfirmDeleteBulkModalLoading: false,
      listModel: {
        filters: {
          ready: false,
          sort: { by: "creationDate", desc: true },
          showCompleted: false,
          showNoTemplateOnly: false,
        },
        cols: {
          showProductionDate: true,
          showDeliveryDate: true,
          showInstallationDate: true,
          showCadCam: true,
          showTemplate: false,
          showWorkOrderName: true,
          showClientName: true,
          showPercentCompleted: true,
          showLastCompletedStep: true,
          showWorkOrderType: true,
          showWorkOrderGroup: true,
          showRoomName: true,
          showActions: true,
          showLate: false,
        },
      },
      selectedRows: [],
      searchQuery: null,
    };
  },
  beforeCreate() { },
  created() {
  },
  beforeDestroy() {
    this.$root.$off("save-workorder", this.reloadWorkOrders);
  },
  mounted() {
    this.$root.$on("save-workorder", this.reloadWorkOrders);
  },
  computed: {
    deleteWorkOrderTitle() {
      return (
        this.$t("general.deleteWorkOrder") + " " + this.selectedWorkOrder?.name
      );
    },
    deleteWorkOrderBulkTitle() {
      return (
        this.$t("general.deleteWorkOrderBulk") +
        " (" +
        this.selectedRows.length +
        ")"
      );
    },
  },
  methods: {
    reloadWorkOrders() {
      this.$refs.list.refresh();
    },
    showDeleteWorkOrderModal(workorder) {
      this.selectedWorkOrder = workorder;
      
      this.$refs.workOrderDeleteModal.show().then(() => {
        this.showConfirmDeleteModal = true;

        CalendarApiService.deleteWorkOrder(workorder.idPublic).then(() => {
          this.showConfirmDeleteModal = false;
          this.trackEvent("DeleteWorkOrder");

        this.selectedWorkOrder = null;

        this.$store.dispatch('calendar/fetchAlerts');

          this.reloadWorkOrders();
        });
      })
    },
    showDeleteWorkOrdersBulkModal() {
      this.$refs.bulkWorkOrderDeleteModal.show().then(() => {
        var selectedWorkOrdersIdPublic = this.selectedRows.map((x) => {
          return x.idPublic;
        });

        if (selectedWorkOrdersIdPublic.length == 0) {
          return null
        } else {
          this.showConfirmDeleteBulkModalLoading = true;
          CalendarApiService.deleteWorkOrders(selectedWorkOrdersIdPublic).then(
            () => {
              this.trackEvent("DeleteWorkOrders");
              this.showConfirmDeleteBulkModalLoading = false;
              this.reloadWorkOrders();
            }
          );
        }
      })
    },
    onRowSelected(items) {
      this.selectedRows = items;
    },
    search() {
      this.listModel.filters.search = this.searchQuery;
    },
    selectAllRows() {
      this.$refs.list.$refs.selectableTable.selectAllRows();
    },
    clearAllRows() {
      this.$refs.list.$refs.selectableTable.clearSelected();
    },
    openBulkChangeDialog() {
      var selectedWorkOrders = this.selectedRows;
      this.$bus.$emit("open-bulk-change-dialog", selectedWorkOrders);
    },
    bulkChangeClosed() { },
    closeWorkOrderProgressDetails() {
      this.$bvModal.hide("workOrderProgressDetails");
    },
    applyFilters(filters) {
      filters.search = this.listModel.filters.search;
      filters.showCompleted = this.listModel.filters.showCompleted;
      filters.showNoTemplateOnly = this.listModel.filters.showNoTemplateOnly;
      this.$set(this.listModel, "filters", filters);
    },
    exportExcel() {
      this.$refs.list.exportExcel();
    }
  },
};
</script>

<template>
  <Layout>
    <div class="contentList" zstyle="margin-bottom:-30px; flex:1 1 0; min-height:0; display:flex; flex-direction:column;">
      <div class="mb-3" style="display: flex; align-items: center">
        <WorkOrderSearchTypeahead class="mr-4" v-model="searchQuery" @search="search()" @clear="search()" @reload="reloadWorkOrders"/>
        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <b-form-checkbox class="mr-3" size="md" switch v-model="listModel.filters.showCompleted">{{
            $t("general.showCompleted")
          }}</b-form-checkbox>
          <b-form-checkbox class="mr-3" size="md" switch v-model="listModel.filters.showNoTemplateOnly">
            {{ $t("general.showNoTemplateOnly") }}
          </b-form-checkbox>
        </div>
        <div style="margin-left: auto; display: flex; align-items: center">
          <i v-b-tooltip.hover :title="$t('general.selectAll')" @click="selectAllRows"
          style="font-size: 1.8em; cursor: pointer" class="mdi mdi-select-all mr-2"></i>
          <i v-b-tooltip.hover :title="$t('general.unselectAll')" @click="clearAllRows"
          style="font-size: 1.8em; cursor: pointer" class="mdi mdi-select-off mr-3"></i>
          <b-button @click="exportExcel" variant="primary" class="mr-2">
            {{ $t('general.exportExcel') }}
            <i class="mdi mdi-file-download"/>
          </b-button>
          <b-dropdown class="mr-2" id="dropdown-1" right variant="primary" :text="$t('general.bulkChange')">
            <b-dropdown-item @click="openBulkChangeDialog">{{
              $t("general.bulkChange")
            }}</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="showDeleteWorkOrdersBulkModal" variant="danger">{{
              $t("general.deleteSelected")
            }}</b-dropdown-item>
          </b-dropdown>
          <list-field-filters v-model="listModel.cols" />
        </div>
      </div>
      <div class="row" zstyle="min-height:0; flex:1 1 0;">
        <div class="col-12">
          <ListFilters @apply-filters="applyFilters" />
        </div>
        <div class="col-12">
          <List ref="list" v-model="listModel" @delete-workorder="showDeleteWorkOrderModal"
            @select-workorders="onRowSelected" />
        </div>
      </div>
    </div>
    <BulkChangeModal @bulk-change-closed="bulkChangeClosed" @bulk-change-applied="$refs.list.reset()" />
    <DeletionModal ref="workOrderDeleteModal" :content="this.$t('general.areYouSureDeleteWorkOrder')" :title="this.$t('general.deleteWorkOrder')"/>
    <DeletionModal ref="bulkWorkOrderDeleteModal" :content="this.$t('general.deleteWorkOrderBulkText')" :title="this.$t('general.deleteWorkOrderBulk')"/>
    <EventDetailsModal ref="eventDetailsModal" />
  </Layout>
</template>
