<script>
import Repositories from "@/repositories";
const WorkOrdersRepository = Repositories.get("workorders");
import UserConfigMixin from "@/mixins/userConfigMixin";
import CalendarApiService from "@/services/calendarService";
import RadialProgressBar from "vue-radial-progress";
import { utils, writeFileXLSX } from 'xlsx';
import ProgressStartModal from "../modals/progressStartModal.vue";
import CompleteStepsBeforeModal from '../../modals/completeStepsBeforeModal.vue';

export default {
  mixins: [UserConfigMixin],
  components: {
    RadialProgressBar,
    ProgressStartModal,
    CompleteStepsBeforeModal
  },
  model: {
    prop: "model",
    event: "input",
  },
  props: ["model"],
  data() {
    return {
      workOrders: [],
      totalRows: 0,
      innerStrokeColor: "#cfcfcf",
      pagesize: 30,
      page: 1,
    };
  },
  created() {
    this.pagesize = localStorage.getItem("workorderlist.pagesize") ?? 30;
  },
  beforeDestroy() {},
  mounted() {},
  methods: {
    reset() {
      this.page = 1;
      this.$refs.selectableTable.refresh();
    },
    refresh() {
      this.$refs.selectableTable.refresh();
    },
    loadWorkOrders(ctx, callback) {
      if (!this.model.filters || !this.model.filters.ready) {
        return [];
      }
      var filters = {
        currentDate: new Date().toDateString(),
        page: ctx.currentPage,
        pagesize: ctx.perPage,
        sortby: ctx.sortBy,
        sortdesc: ctx.sortBy ? ctx.sortDesc.toString() : undefined,
        search: this.model.filters.search,
        workordertypes: this.model.filters.workOrderType
          ? [this.model.filters.workOrderType.idPublic]
          : undefined,
        workOrdergroups: this.model.filters.workOrderGroups.map((x) => {
          return x.idPublic;
        }),
        roomName: this.model.filters.roomName,
        clientName: this.model.filters.clientName,
        contractor: this.model.filters.contractor,
        projectmanager: this.model.filters.projectManager,
        installers: this.model.filters.installers.map((i) => {
          return i.idPublic;
        }),
        shippers: this.model.filters.shippers.map((s) => {
          return s.idPublic;
        }),
        showcompleted: this.model.filters.showCompleted.toString(),
        showNoTemplateOnly: this.model.filters.showNoTemplateOnly.toString(),
        deliveryDateRangeFromStartDate:
          this.model.filters.deliveryDateRange &&
          this.model.filters.deliveryDateRange[0]
            ? this.$dayjs(this.model.filters.deliveryDateRange[0]).format(
                "YYYY-MM-DD"
              )
            : null,
        deliveryDateRangeToStartDate:
          this.model.filters.deliveryDateRange &&
          this.model.filters.deliveryDateRange[1]
            ? this.$dayjs(this.model.filters.deliveryDateRange[1]).format(
                "YYYY-MM-DD"
              )
            : null,
        installationDateRangeFromStartDate:
          this.model.filters.installationDateRange &&
          this.model.filters.installationDateRange[0]
            ? this.$dayjs(this.model.filters.installationDateRange[0]).format(
                "YYYY-MM-DD"
              )
            : null,
        installationDateRangeToStartDate:
          this.model.filters.installationDateRange &&
          this.model.filters.installationDateRange[1]
            ? this.$dayjs(this.model.filters.installationDateRange[1]).format(
                "YYYY-MM-DD"
              )
            : null,
        productionDateRangeFromStartDate:
          this.model.filters.productionDateRange &&
          this.model.filters.productionDateRange[0]
            ? this.$dayjs(this.model.filters.productionDateRange[0]).format(
                "YYYY-MM-DD"
              )
            : null,
        productionDateRangeToStartDate:
          this.model.filters.productionDateRange &&
          this.model.filters.productionDateRange[1]
            ? this.$dayjs(this.model.filters.productionDateRange[1]).format(
                "YYYY-MM-DD"
              )
            : null,
      };
      WorkOrdersRepository.get(filters).then((response) => {
        this.workOrders = response.data.items;
        this.totalRows = response.data.totalCount;
        callback(this.workOrders);
      });
    },
    getWorkOrderValue(measureType, workOrder) {
      let value = null;
      let workOrderValue = workOrder.workOrderValues.filter((x) => {
        return x.item1 == measureType;
      });
      if (workOrderValue.length > 0) {
        value = workOrderValue[0].item2;
      }

      return value;
    },
    toggleWorkOrderSteps(row) {
      row.toggleDetails();

      if (!row.detailsShowing) {
        this.$set(row.item, "isLoading", true);
        row.item.isLoading = true
        this.getProgress(row);
      }
    },
    checkIfIsLate(item) {
      if (item.status === "Pending") {
        return item.startDate === null ? false : new Date(item.startDate) < new Date ? true : false;
      }
      if (item.status === "Started") {
        return item.endDate === null ? false : new Date(item.endDate) < new Date ? true : false;
      }
      return false;
    },
    getProgressBarColor(percent) {
      if (percent == 1) {
        return "#34c38f";
      } else if (percent == 0) {
        return "rgba(52, 58, 64, 0.31)";
      } else {
        return "#f1b44c";
      }
    },
    openWorkOrderProgressDetails(item, row) {
      this.$refs.progressStartModal.show(item).then((response) => {
        if (response == "complete") {
          this.openCompleteStepsBeforeModal(item, row);
        }
        if (response == "start") {
          row.item.isLoading = true;
          this.startProgressAppointment(item).then(() => {
            this.getProgress(row)
          })
        }
      })
    },
    openCompleteStepsBeforeModal(item, row) {
      CalendarApiService.getProgressConfig(item.progress.id).then((response) => {
        let config = response.data.data;
        
        if (config.askToCompletePreviousProgress == true && item.progress.id != item.nextStepToCompleteId) {
          this.$refs.completeStepsBeforeModal.show().then(
            (userResponse) => {
              row.item.isLoading = true;
              this.completeProgressAppointment(item, userResponse).then(() => {
                this.getProgress(row)
              })
            },
            () => {}
          );
        } else {
          row.item.isLoading = true;
          this.completeProgressAppointment(item, true).then(() => {
            this.getProgress(row)
          })
        }
      });
    },
    completeProgressAppointment( progressAppointment, completeProgressBefore = false ) {
      return CalendarApiService.completeProgressAppointment( progressAppointment.workOrderStepIdPublic, completeProgressBefore );
    },
    startProgressAppointment(progressAppointment) {
      return CalendarApiService.startProgressAppointment(progressAppointment.workOrderStepIdPublic);
    },
    getProgress(row) {
      CalendarApiService.getWorkOrderProgress(row.item.idPublic).then(
        (response) => {
          var orderedProgress = response.data.data.sort((a, b) =>
          a.progress.order > b.progress.order
          ? 1
          : b.progress.order > a.progress.order
          ? -1
          : 0
          )
          .map(x => {
            return {
              ...x,
              isLate: this.checkIfIsLate(x)
            }
          })

          let formatedOrderedProgress = orderedProgress.map((progress, i) => {
            return {
              ...progress,
              nextStepToCompleteId: orderedProgress[i + 1]?.workOrderStepIdPublic ?? null
            }
          })

          row.item.workOrderProgress = formatedOrderedProgress;
          row.item.isLoading = false;
        }
      );
    },
    exportExcel() {
      // SheetJS
      const ws = utils.json_to_sheet(this.workOrders.map((row) => {
        let dynamicRow = {};

        delete row.workOrderStepsStatuses;
        delete row.idPublic;
        row.lastCompletedStep = row.lastCompletedStep?.name;

        for (let field of this.fields) {
          if (this.measureTypes.find(x => x == field.key)) {
            dynamicRow[field.key] = this.getWorkOrderValue(field.key, row);
          }
          if (row[field.key] != null) {
            dynamicRow[field.key] = row[field.key]
          }
        }
        return dynamicRow;
      }));
      
      ws['!autofilter']={ref:"A1:Z1"}
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, `workorders-export-${this.$dayjs(new Date).format("L")}.xlsx`);
    }
  },
  computed: {
    fields() {
      const lang = this.$i18n.localeComplete;

      if (!lang) {
        return [];
      }
      // Return the array of translated values
      var tableFields = [];
      if (this.model.cols.showWorkOrderName == true) {
        tableFields.push({
          key: "name",
          label: this.$t("general.workOrder"),
          sortable: true,
        });
      }
      if (this.model.cols.showClientName == true) {
        tableFields.push({
          key: "clientName",
          label: this.$t("general.client"),
          sortable: true,
        });
      }
      if (this.model.cols.showPercentCompleted == true) {
        tableFields.push({
          key: "percentCompleted",
          label: this.$t("general.advancement"),
          sortable: true,
        });
      }
      if (this.model.cols.showLastCompletedStep == true) {
        tableFields.push({
          key: "lastCompletedStep",
          label: this.$t("general.status"),
          sortable: true,
        });
      }
      if (this.model.cols.showWorkOrderType == true) {
        tableFields.push({
          key: "workOrderTypeName",
          label: this.$t("general.type"),
          sortable: true,
        });
      }
      if (this.model.cols.showWorkOrderGroup == true) {
        tableFields.push({
          key: "workOrderGroupName",
          label: this.$t("general.group"),
          sortable: true,
        });
      }
      if (this.model.cols.showRoomName == true) {
        tableFields.push({
          key: "roomName",
          label: this.$t("general.room"),
          sortable: true,
        });
      }
      if (this.model.cols.showProductionDate == true) {
        tableFields.push({
          key: "productionDate",
          label: this.$t("general.production"),
          sortable: true,
        });
      }

      if (this.model.cols.showDeliveryDate == true) {
        tableFields.push({
          key: "deliveryDate",
          label: this.$t("general.delivery"),
          sortable: true,
        });
      }

      if (this.model.cols.showInstallationDate == true) {
        tableFields.push({
          key: "installationDate",
          label: this.$t("general.installation"),
          sortable: true,
        });
      }

      if (this.model.cols.showCadCam == true) {
        tableFields.push({ key: "cadCamMerged", label: this.$t("general.CadCam") });
      }

      if (this.model.cols.showLate == true) {
        tableFields.push({
          key: "nbLateProgresses",
          label: this.$t("general.late"),
        });
      }

      if (this.model.cols.showTemplate == true) {
        tableFields.push({
          key: "templateName",
          label: this.$t("general.template"),
          sortable: true,
        });
      }

      for (let mt of this.measureTypes) {
        if (this.model.cols[mt] == true) {
          tableFields.push({
            key: mt,
            label: this.$t("general." + mt),
            sortable: true,
          });
        }
      }
      if (this.model.cols.showActions == true) {
        tableFields.push({
          key: "show_details",
          label: this.$t("general.actions"),
        });
      }

      return tableFields;
    },
  },
  watch: {
    pagesize: function () {
      localStorage.setItem("workorderlist.pagesize", this.pagesize);
    },
  },
};
</script>

<template>
  <div class="mb-0 card card-body boxShadow">
    <div class="mt-2 workOrderList">
      <b-table
        class=""
        selectable
        @row-selected="(rows) => $emit('select-workorders', rows)"
        select-mode="multi"
        sticky-header
        responsive
        outlined
        hover
        head-variant="light"
        ref="selectableTable"
        style="flex: 1 1 0; max-height: 100%; scrollbar-width: thin"
        :items="loadWorkOrders"
        :fields="fields"
        :per-page="pagesize"
        :current-page="page"
        :filter="model.filters"
        :sort-by="model.filters.sort.by"
        :sort-desc="model.filters.sort.desc"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner
              class="align-middle"
              style="color: rgba(243, 168, 33, 0.7) !important"
            ></b-spinner>
          </div>
        </template>
        <template #cell(name)="data">
          <div>
            {{ data.item.name }}
          </div>
        </template>

        <template #cell(clientName)="data">
          <div>
            {{ data.item.clientName }}
          </div>
        </template>

        <template #cell(percentCompleted)="data">
          <div style="text-align: center">
            <b-badge
              :style="{
                'background-color':
                  data.item.percentCompleted == 1
                    ? '#34c38f'
                    : data.item.percentCompleted == 0
                    ? 'rgba(52, 58, 64, 0.31)'
                    : '#f1b44c',
              }"
              style="font-size: 1.1em"
              >{{ (data.item.percentCompleted * 100).toFixed(0) }}%</b-badge
            >
          </div>
        </template>

        <template #cell(lastCompletedStep)="data">
          <b-badge
            v-if="data.item.lastCompletedStep"
            :style="{
              'background-color': data.item.lastCompletedStep.colorHexa,
            }"
            style="font-size: 1.1em"
            >{{ data.item.lastCompletedStep.name }}</b-badge
          >
        </template>

        <template #cell(roomName)="data">
          <div>
            {{ data.item.roomName }}
          </div>
        </template>

        <template #cell(cadCamMerged)="data">
          <div style="text-align: center">
            <i
              v-if="data.item.cadCamMerged == true"
              class="bx bx-check-circle"
              style="font-size: 1.6em; color: #34c38f"
            />
          </div>
        </template>

        <template #cell(productionDate)="data">
          {{
            !data.item.productionDate
              ? ""
              : $dayjs(data.item.productionDate).format("L")
          }}
        </template>

        <template #cell(deliveryDate)="data">
          {{
            !data.item.deliveryDate
              ? ""
              : $dayjs(data.item.deliveryDate).format("L")
          }}
        </template>

        <template #cell(installationDate)="data">
          {{
            !data.item.installationDate
              ? ""
              : $dayjs(data.item.installationDate).format("L")
          }}
        </template>

        <template #cell(nbLateProgresses)="data">
          <div class="d-flex" v-if="data.item.nbLateProgresses >=1">
            {{ data.item.nbLateProgresses }}
            <i class='bx bx-time bx-tada pl-1' style='color:#ff0000; font-size: large;' ></i>
          </div>
          <div v-else class="d-flex">
            <i class='bx bx-time' style='color: #6CC855; font-size: large;' ></i>
          </div>
        </template>
        
        <!-- //VALUES -->
        <template #cell(SquareFoot)="data">
          <div style="text-align: center">
            {{ getWorkOrderValue("SquareFoot", data.item) }}
          </div>
        </template>

        <template #cell(SquareMeter)="data">
          <div style="text-align: center">
            {{ getWorkOrderValue("SquareMeter", data.item) }}
          </div>
        </template>

        <template #cell(NumericValue)="data">
          <div style="text-align: center">
            {{ getWorkOrderValue("NumericValue", data.item) }}
          </div>
        </template>

        <template #cell(SheetsQuantity)="data">
          <div style="text-align: center">
            {{ getWorkOrderValue("SheetsQuantity", data.item) }}
          </div>
        </template>

        <template #cell(TimePerWorkOrder)="data">
          <div style="text-align: center">
            {{ getWorkOrderValue("TimePerWorkOrder", data.item) }}
          </div>
        </template>

        <template #cell(DoorsQuantity)="data">
          <div style="text-align: center">
            {{ getWorkOrderValue("DoorsQuantity", data.item) }}
          </div>
        </template>

        <template #cell(Products)="data">
          <div style="text-align: center">
            {{ getWorkOrderValue("Products", data.item) }}
          </div>
        </template>

        <template #cell(Parts)="data">
          <div style="text-align: center">
            {{ getWorkOrderValue("Parts", data.item) }}
          </div>
        </template>
        <!-- //END VALUES -->

        <template #head(show_details)="data">
          <div style="display: flex; align-items: center">
            <div class="mr-2" v-html="data.field.label"></div>
          </div>
        </template>
        <template #cell(show_details)="row">
          <div style="display: flex; justify-content: center">
            <i
              v-b-tooltip.hover
              :title="$t('general.details')"
              @click.stop="toggleWorkOrderSteps(row)"
              :style="{ color: row.detailsShowing ? '#34c38f' : '#556ee6' }"
              style="font-size: 1.7em"
              class="bx bx-detail mr-2"
            />
            <i 
              v-b-tooltip.hover
              :title="$t('general.copy')"
              v-if="userCanCreateWorkOrder"
              @click.stop="$root.$emit('copy-workorder', row.item.idPublic)"
              style="font-size: 1.7em; color: #556ee6;"
              class="bx bx-copy mr-2" />
            <i
              v-b-tooltip.hover
              v-if="userCanCreateWorkOrder"
              :title="$t('general.edit')"
              @click.stop="$root.$emit('edit-workorder', row.item.idPublic)"
              style="font-size: 1.7em; color: #556ee6"
              class="bx bxs-edit mr-2"
            />

            <i
              v-if="userCanDeleteWorkOrder"
              v-b-tooltip.hover
              :title="$t('general.delete')"
              @click.stop="$emit('delete-workorder', row.item)"
              variant="danger"
              style="font-size: 1.7em; color: #f46a6a"
              class="bx bx-trash"
            />
          </div>
        </template>

        <template #row-details="row">
          <div style="max-width: 100%">
            <div v-if="row.item.isLoading" style="text-align: center" class="w-100 justify-content-center">
              <b-spinner
                style="color: rgba(243, 168, 33, 0.7) !important"
                label="Spinning"
              ></b-spinner>
            </div>
            <div
              v-else
              v-show="row.item.workOrderProgress"
              class="zcard zcard-body mb-0"
              style="
                overflow: auto;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: flex-start;
              "
            >
              <div
                v-for="(workOrderProgressItem, index) in row.item.workOrderProgress"
                @click="openWorkOrderProgressDetails(workOrderProgressItem, row)"
                :key="index"
                class="mr-4 mb-2"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  padding: 10px;
                "
              >
                <label
                  style="white-space: nowrap"
                  class="linkWorkOrderStepHover"
                >
                  {{ workOrderProgressItem.progress.name }}
                  <i v-if="workOrderProgressItem.isLate" class='bx bx-time bx-tada' style='color:#ff0000' ></i>
                </label>
                <radial-progress-bar
                  :diameter="60"
                  :strokeWidth="4"
                  :startColor="
                    getProgressBarColor(workOrderProgressItem.percentCompleted)
                  "
                  :stopColor="
                    getProgressBarColor(workOrderProgressItem.percentCompleted)
                  "
                  :innerStrokeColor="innerStrokeColor"
                  :innerStrokeWidth="4"
                  strokeLinecap="square"
                  :completed-steps="
                    workOrderProgressItem.percentCompleted * 100
                  "
                  :total-steps="100"
                >
                  <span style="font-size: 0.8em"
                    >{{
                      Math.floor(workOrderProgressItem.percentCompleted * 100)
                    }}
                    %</span
                  >
                </radial-progress-bar>
                <div v-if="workOrderProgressItem.dateStarted && !workOrderProgressItem.dateDone">
                  {{ $t('general.dateStarted') }}
                  <div class="text-primary text-center">
                    {{ $dayjs(workOrderProgressItem.dateStarted).format("L") }}
                  </div>
                </div>
                <div v-if="workOrderProgressItem.dateDone">
                  {{ $t('general.dateDone') }}
                  <div class="text-primary text-center">
                    {{ $dayjs(workOrderProgressItem.dateDone).format("L") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-table>
      <div class="d-flex align-items-center justify-content-between">
        <b-pagination
          v-model="page"
          :total-rows="totalRows"
          :per-page="pagesize"
          :first-text="$t('general.first')"
          :prev-text="$t('general.prev')"
          :next-text="$t('general.next')"
          :last-text="$t('general.last')"
        >
        </b-pagination>
        <b-form-group
          :label="$t('general.itemPerPage')"
          label-for="pagesize-input"
          label-cols-sm="8"
          label-align-sm="right"
          content-cols-sm="4"
          class="w-25"
          ><b-form-select
            id="pagesize-input"
            v-model="pagesize"
            :options="[30, 50, 100]"
          />
        </b-form-group>
      </div>
    </div>
    <ProgressStartModal ref="progressStartModal"/>
    <CompleteStepsBeforeModal ref="completeStepsBeforeModal" />
  </div>
</template>
